import { PrismicImage, PrismicPageDefinition, PrismicRichText } from "./types";
import { PrismicRawResourceData } from "./resources";

export type TypeDedicatedHostingService = {
  image: PrismicImage;
  title1: string;
  description: string;
};

export type TypeDedicatedHostingHowItWorksItem = {
  step_title: string;
  step: string;
  description: string;
  image: PrismicImage;
};

export type TypeDedicatedHostingOptionsPage = {
  title: string;
  subtitle: PrismicRichText;
  meta_title: string;

  section_title: string;
  section_title1: string;
  section_title2: string;

  section_subtitle: PrismicRichText;
  section_subtitle1: PrismicRichText;

  cta_text: string;
  cta_button_text: string;
  cta_link: PrismicPageDefinition;
  second_cta_button_text: string;
  second_cta_button_link: PrismicPageDefinition;

  steps: TypeDedicatedHostingHowItWorksItem[];

  services: TypeDedicatedHostingService[];
  quotes: Array<{
    author_name: string;
    author_position: string;
    quote: string;
    logo: PrismicImage;
  }>;
  hero_image: PrismicImage;
  customer_stories: Array<{
    case_study: {
      document: {
        data: PrismicRawResourceData;
      };
    };
  }>;

  page_meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;
};

export type TypeDedicatedHostingOptionsQueryResult = {
  allPrismicDedicatedHostingV2: {
    edges: Array<{
      node: { data: TypeDedicatedHostingOptionsPage };
    }>;
  };
};

export const getPage = (
  data: TypeDedicatedHostingOptionsQueryResult
): TypeDedicatedHostingOptionsPage => {
  return data.allPrismicDedicatedHostingV2.edges[0].node.data;
};
