import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./dedicated-cloud.scss";
import {
  getPage,
  TypeDedicatedHostingHowItWorksItem,
  TypeDedicatedHostingOptionsPage,
  TypeDedicatedHostingOptionsQueryResult,
} from "../../utils/queries/dedicated_hosting_services";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";
import { ResourceInteruption } from "../../templates/resources/customers";
import { QuoteCarousel } from "../../components/reusable/quote-carousel";
import { TypeHomepageV2Quote } from "../../utils/queries/homepagev2";
import {
  CustomerStandardCard,
  StandardCard,
} from "../../templates/resources/cards";
import { Resource } from "../../utils/queries/resources";

const query = graphql`
  {
    allPrismicDedicatedHostingV2 {
      edges {
        node {
          data {
            cta_button_text
            cta_text
            page_meta_description
            second_cta_button_text
            section_subtitle
            section_subtitle1
            section_title
            section_title1
            section_title2
            title
            meta_title
            hero_image {
              alt
              url
            }
            subtitle {
              html
              raw
              text
            }
            steps {
              step_title
              step
              description
              image {
                alt
                url
              }
            }
            services {
              title1
              description
              image {
                alt
                url
              }
            }
            second_cta_button_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                  }
                }
              }
            }
            cta_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                  }
                }
              }
            }
            quotes {
              quote
              author_position
              author_name
              logo {
                alt
                url
              }
            }
            page_meta_thumbnail {
              url
              alt
            }
            customer_stories {
              case_study {
                document {
                  ... on PrismicResource {
                    id
                    data {
                      title
                      tags {
                        tag {
                          document {
                            ... on PrismicResourceIndustry {
                              id
                              data {
                                industry
                                display_in_filter_dropdown
                              }
                            }
                            ... on PrismicResourceTopic {
                              id
                              data {
                                topic
                                display_in_filter_dropdown
                              }
                            }
                          }
                        }
                      }
                      post_date
                      featured_on_customer_page
                      description {
                        html
                        raw
                        text
                      }
                      company_name
                      featured
                      link {
                        url
                        document {
                          ... on PrismicCaseStudy {
                            id
                            uid
                          }
                        }
                      }
                      image {
                        alt
                        url
                      }
                      resource_type {
                        document {
                          ... on PrismicResourceType {
                            id
                            data {
                              display_in_filter_dropdown
                              type
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const HowItWorksStage: React.FC<{
  step: TypeDedicatedHostingHowItWorksItem;
  index: number;
}> = ({ index, step }) => {
  const imageLeft = index % 2 === 0;
  let children = [
    <div className="col-12 col-lg-6">
      <h3 className="title">
        Step {step.step}
        <span className="slashes">//</span> {step.step_title}
      </h3>
      <p className="description">{step.description}</p>
    </div>,
    <div className="col-12 col-lg-6 hidden lg:block">
      <img className="w-full" src={step.image.url} />
    </div>,
  ];

  if (!imageLeft) {
    children = children.reverse();
  }

  return (
    <div className="col-12 step mb-4">
      <div className="row flex justify-center items-center">{children}</div>
    </div>
  );
};

const DedicatedHostingOptionsPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeDedicatedHostingOptionsQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "prismicDedicatedHostingV2",
    true
  ) as TypeDedicatedHostingOptionsPage;

  const renderableResources = result.customer_stories.map(
    (rawResource) => new Resource(rawResource.case_study.document.data)
  );

  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="dedicated-hosting-services">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>{" "}
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />
        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={result.title}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />
        <section id="services">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2>{RichTextRender(result.section_title1)}</h2>
              </div>
              <div className="col-12 col-lg-8 text-center">
                <p>{RichTextRender(result.section_subtitle1)}</p>
              </div>
            </div>

            <div className="row">
              {result.services.map((service, index) => (
                <div key={index} className="col-12 col-lg-6 service">
                  <img
                    src={service.image.url}
                    alt={service.image.alt}
                    loading="lazy"
                  />
                  <h3 className="service-title">{service.title1}</h3>
                  <p className="description">{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section id="how-it-works">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-8 text-center">
                <h2>{RichTextRender(result.section_title)}</h2>
                <p>{RichTextRender(result.section_subtitle)}</p>
              </div>

              <div className="col-12 col-lg-10">
                <div className="row">
                  {result.steps.map((step, index) => (
                    <HowItWorksStage step={step} key={index} index={index} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <ResourceInteruption
          cta_text={result.cta_text}
          cta_button_text={result.cta_button_text}
          second_cta_button_text={result.second_cta_button_text}
          cta_link={result.cta_link}
          second_cta_button_link={result.second_cta_button_link}
          topMargin={false}
        />
        <section id="quotes">
          <div className="container">
            <div className="row">
              <div className="col-12 quote-carousel-section">
                <QuoteCarousel
                  withComma={true}
                  quotes={result.quotes.map(
                    (quote) =>
                      ({
                        image: quote.logo,
                        text: quote.quote,
                        author_name: quote.author_name,
                        author_title: quote.author_position,
                      } as TypeHomepageV2Quote)
                  )}
                />
              </div>
            </div>
          </div>
        </section>
        <section id="customers">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2>{RichTextRender(result.section_title2)}</h2>
              </div>
            </div>

            <div className="row">
              {renderableResources.map((resource, index) =>
                resource.companyName ? (
                  <CustomerStandardCard resource={resource} key={index} />
                ) : (
                  <StandardCard resource={resource} key={index} />
                )
              )}
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticDedicatedHostingOptionsPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <DedicatedHostingOptionsPage location={location} data={data} />
      )}
    />
  );
};
export default StaticDedicatedHostingOptionsPage;
